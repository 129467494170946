const __SEPARATOR__ = ' '

export const addFocusedBy = (focusedBy: string): void => {
  const focusedByAttr = document.body.getAttribute('data-focused-by')

  if (null == focusedByAttr) {
    document.body.setAttribute('data-focused-by', focusedBy)
    return
  }

  const focusedByArray = focusedByAttr.split(__SEPARATOR__)
  focusedByArray.push(focusedBy)

  document.body.setAttribute('data-focused-by', focusedByArray.join(__SEPARATOR__))
}

export const removeFocusedBy = (focusedBy: string): void => {
  const focusedByAttr = document.body.getAttribute('data-focused-by')

  if (null == focusedByAttr) {
    return
  }

  const focusedByArray = focusedByAttr.split(__SEPARATOR__)
  const lastIndex = focusedByArray.lastIndexOf(focusedBy)

  if (lastIndex === -1) {
    return
  }

  focusedByArray.splice(lastIndex, 1)

  if (focusedByArray.length === 0) {
    document.body.removeAttribute('data-focused-by')
    return
  }

  document.body.setAttribute('data-focused-by', focusedByArray.join(__SEPARATOR__))
}
