'use strict'

export interface SelectCustomConfig {
  isMultiple?: boolean
  placeholder?: string
  onClick?: () => void
  onFocus?: () => void
  onBlur?: () => void
  onValuesSet?: (values: string[]) => void
  onValidFields?: (config: SelectCustomConfig) => void
  onInvalidFields?: (config: SelectCustomConfig) => void
}

export default class SelectCustom {
  element!: HTMLDetailsElement
  summaryEl!: HTMLElement
  summaryTextEl!: HTMLElement
  selectEl!: HTMLSelectElement
  config!: SelectCustomConfig
  values!: string[]
  selectedItems: HTMLElement[] = []

  constructor(element: string | HTMLDetailsElement, config?: SelectCustomConfig) {
    let el: HTMLDetailsElement | string | null = element

    if (typeof element === 'string') {
      el = document.querySelector<HTMLDetailsElement>(element)
    }

    if (!el || typeof el === 'string') {
      return
    }

    this.element = el
    this.config = config ?? {}

    const summaryEl = this.element.querySelector<HTMLElement>('summary')

    if (!summaryEl) {
      return
    }

    this.summaryEl = summaryEl

    const selectEl = this.summaryEl.querySelector<HTMLSelectElement>('select')

    if (!selectEl) {
      return
    }

    this.selectEl = selectEl

    const summaryTextEl = this.summaryEl.querySelector<HTMLElement>('.o-select-custom__text')

    if (!summaryTextEl) {
      return
    }

    if (this.element.hasAttribute('data-placeholder') && !this.config.placeholder) {
      this.config.placeholder = this.element.getAttribute('data-placeholder') as string
    }

    if (this.selectEl.hasAttribute('multiple') && !this.config.isMultiple) {
      this.config.isMultiple = true
    }

    this.summaryTextEl = summaryTextEl
    this.values = []

    Array.from(this.selectEl.selectedOptions).forEach((optionEl: HTMLOptionElement) => {
      this.values.push(optionEl.value)
    })

    if (this.values.length > 0) {
      this.setValues(this.values)
    }

    this.setEvents()
  }

  private setEvents(): void {
    this.element.addEventListener('click', (e) => {
      const target = e.target as HTMLElement

      if (target !== this.element && !this.summaryEl.contains(target)) {
        return
      }

      this.config.onClick && this.config.onClick()

      if (this.element.open) {
        this.config.onBlur && this.config.onBlur()
        this.element.querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"]').forEach((el) => {
          el.tabIndex = -1
        })
      } else {
        this.config.onFocus && this.config.onFocus()
        this.element.querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"]').forEach((el) => {
          el.tabIndex = 1
        })
      }
    })

    this.handleKeyboardKeys()
    this.handleItemsClick()

    window.addEventListener('click', (e) => {
      const target = e.target as HTMLElement

      if (!this.element.contains(target) && this.element.open) {
        this.element.removeAttribute('open')

        this.config.onBlur && this.config.onBlur()

        this.handleUnFocus()
      }
    })

    this.config.onFocus && this.element.addEventListener('focus', this.config.onFocus)
  }

  setValues(values: string[]): void {
    this.element
      .querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"][data-item-selected]')
      .forEach((itemEl) => itemEl.removeAttribute('data-item-selected'))

    this.selectEl.querySelectorAll<HTMLOptionElement>('option[selected]').forEach((optionEl) => (optionEl.selected = false))

    this.selectedItems = []

    values.forEach((value) => {
      const itemEl = this.element.querySelector<HTMLElement>(`ul[role="listbox"] [role="option"][data-value="${value}"]`)

      if (!itemEl) {
        return
      }

      this.selectItem(itemEl)
      this.selectedItems.push(itemEl)

      const optionEl = this.selectEl.querySelector<HTMLOptionElement>(`option[value="${value}"]`)

      if (optionEl) {
        optionEl.selected = true
      }
    })

    this.setText()
  }

  close(): void {
    this.config.onBlur && this.config.onBlur()
    this.element.removeAttribute('open')
  }

  private setText(): void {
    const text: string[] = []
    const values: string[] = []

    this.selectedItems.forEach((itemEl) => {
      text.push(itemEl.getAttribute('data-label-text') as string)
      values.push(itemEl.getAttribute('data-value') as string)
    })

    this.values = values

    this.selectEl.dispatchEvent(new Event('change'))

    this.config.onValuesSet?.(values)

    this.summaryTextEl.innerHTML = text.length > 0 ? text.join(', ') : this.config.placeholder ?? ''
  }

  private handleUnFocus(): void {
    if (!this.config.isMultiple && !this.selectEl.checkValidity() && this.config.onInvalidFields) {
      this.config.onInvalidFields(this.config)
    } else if (!this.config.isMultiple && this.config.onValidFields) {
      this.config.onValidFields(this.config)
    }

    if (this.config.isMultiple && !this.selectEl.checkValidity() && this.config.onInvalidFields) {
      this.config.onInvalidFields(this.config)
    } else if (this.config.isMultiple && this.config.onValidFields) {
      this.config.onValidFields(this.config)
    }
  }

  private selectItem(el: HTMLElement): void {
    el.setAttribute('data-item-selected', 'true')
  }

  private unselectItem(el: HTMLElement): void {
    el.removeAttribute('data-item-selected')
  }

  private handleItemsClick(): void {
    const items = this.element.querySelectorAll<HTMLElement>('ul[role="listbox"]:not([data-multiple]) [role="option"]')

    items.forEach((itemEl) =>
      itemEl.addEventListener('click', () => {
        const values: string[] = []

        this.element.querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"][data-item-selected]').forEach((el) => {
          this.unselectItem(el)
        })

        values.push(itemEl.getAttribute('data-value') as string)

        this.selectItem(itemEl)

        this.setValues(values)
        this.close()
      })
    )

    const itemsMultiple = this.element.querySelectorAll<HTMLElement>('ul[role="listbox"][data-multiple] [role="option"]')

    itemsMultiple.forEach((itemEl) =>
      itemEl.addEventListener('click', () => {
        const values: string[] = []
        itemEl.hasAttribute('data-item-selected') ? this.unselectItem(itemEl) : this.selectItem(itemEl)

        this.element.querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"][data-item-selected]').forEach((el) => {
          values.push(el.getAttribute('data-value') as string)
        })

        this.setValues(values)
      })
    )
  }

  private handleKeyboardKeys(): void {
    this.element.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        document.body.click()
      }
    })

    this.element.querySelectorAll<HTMLElement>('ul[role="listbox"] [role="option"]').forEach((el) => {
      el.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          el.click()
        }
      })
    })
  }
}
