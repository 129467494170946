'use strict'

export const showLoading = (buttonEl: HTMLButtonElement): boolean => {
  if (!buttonEl) {
    return false
  }

  const textEl = buttonEl.querySelector('.o-button__text')

  if (!textEl) {
    return false
  }

  // in chrome and safari, disabling the button will prevent the form from submitting
  // using settimeout 0 we can still disable the button after the form is submitted
  // also using the setTimeout will allow safari ios to show the animation
  setTimeout(function () {
    buttonEl.setAttribute('disabled', 'disabled')
    textEl.classList.add('dca-skeleton__block')
    textEl.classList.add('dca-skeleton__block--alt')
  }, 0)

  return true
}

export const hideLoading = (buttonEl: HTMLButtonElement): boolean => {
  if (!buttonEl) {
    return false
  }

  const textEl = buttonEl.querySelector('.o-button__text')

  if (!textEl) {
    return false
  }

  buttonEl.removeAttribute('disabled')
  textEl.classList.remove('dca-skeleton__block')
  textEl.classList.remove('dca-skeleton__block--alt')

  return true
}
